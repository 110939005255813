var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "outer-box vertical-center-transform" }, [
    _c("div", { staticClass: "posRel" }, [
      _c("div", { staticClass: "col-xs-12 col-md-6" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 login-box" },
          [
            _vm._m(0),
            !_vm.accountVerified
              ? _c("Auth", {
                  attrs: { busy: _vm.busy, errorMessage: _vm.errorMessage },
                  on: { submit: _vm.authenticateUser }
                })
              : _c("TwoStep", {
                  attrs: {
                    busy: _vm.busy,
                    user: _vm.user,
                    errorMessage: _vm.errorMessage
                  },
                  on: {
                    submit: _vm.loginUser,
                    authenticateUserAgain: _vm.authenticateUser
                  }
                })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "col-xs-12 col-md-6 video-out vertical-center-transform"
        },
        [_c("div", { staticClass: "video-box" }, [_c("TutorialVideos")], 1)]
      ),
      _c("div", { staticClass: "clearfix" })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 text-center" }, [
      _c("img", {
        staticClass: "pickl-logo",
        attrs: { src: "/img/pickl-logo.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }